body {
    background-color: #000000;
    min-width: 700px;
    /* max-width: 1200px; */
}

.header {
    /* padding-top: 10px; */
    height: 40px;
    background-color: #a60a0a;

    display: flex;
    justify-content: center;
}

.title {
    font-size: 20px;
    font-weight: bold;
    color: white;
    width: 100%;
    margin: auto;
    /* text-transform: uppercase; */
}

.email {
    font-size: 11px;
    width: 55px;
    margin: auto;

  }
  
  .emailLink {
    color: white;
  }

a {
    color: #000000;
    text-decoration: none; /* Remove underlines from hyperlinks */
}
a:hover {
    color: #a60a0a;
}

