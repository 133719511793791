.app {
    text-align: center;
    width: 90%;
    margin: 0 auto;
    font-family: Arial, Helvetica, sans-serif;
    background-color: white;
    height: 100%;
  }

.player-selector-section {
    background-color: #000000;
    height: 85px;
    
}
.player-selector-container-items {
    display: flex;
    justify-content: center;
    padding: 5px;
}
/* .player-selector-1 {
    padding: 5px;
} */
.player-selector-2-pad {
    padding-top: 8px;
    padding-left: 10px;
    padding-right: 10px;
    color: white;
}
/* .player-selector-2 {
    padding-top: 5px;
} */
.player-selector-button-container {
    vertical-align: middle;
    display: flex;
    justify-content: center;
}
.player-selector-button {
    background-color: #a60a0a;
    border: 1px solid grey;
    width: 140px;
}

.summary-container {
    padding: 10px 0 30px 0;
    width: 100%;
    display: table;
}
.summary-section {
    width: 33%;
    display: table-cell;
}
.game {
    margin: 2px 0;
    min-height: 25px;
    padding: 1px;
    width: 100%;
    font-size: 10pt;
}
.game-with-interactions {
    background-color: #ffffe0;
    border: 1px solid darkgrey;
}
.game-without-interactions {
    border: 1px dashed grey;
    color: grey;
}
.interactions-container {
    min-height: 300px;
}
.interaction {
    background-color: #FFF;
    margin: 5px auto;
    min-height: 25px;
    padding: 1px;
    border: 1px solid black;
    border-radius: 10px;
    width: 600px;
    font-size: 10pt;
}

.section-label {
    font-size: 16pt;
    font-weight: bold;
    text-transform: uppercase;
}

.section-label2 {
  font-size: 11pt;
  font-weight: bold;
  text-transform: uppercase;
}

.section-label3 {
  font-size: 9pt;
  text-transform: uppercase;
}

.explanation-text {
  font-size: 8pt;
}

.image-headshot {
    border-bottom-left-radius: 50%;
    border-top-left-radius: 50%;
    border-bottom-right-radius: 50%;
    border-top-right-radius: 50%;
    border: black;
    border-width: 1px;
    border-style: solid;
    width: 150px;
    height: 150px;
}

.feedback {
  background-color: #000000;
  color: white;

  /* Dock the button at the bottom of the page */
  /* https://stackoverflow.com/questions/2971401/how-to-anchor-a-div-to-the-bottom-of-a-page */
  position:fixed;
  left:0px;
  bottom:0px;
  height:30px;
  width:100%;

  display: flex;
  justify-content: center;
}

.twitterdm {
  background-color: #000000;
  color: white;
  width: 90px
}

.react-autosuggest__container {
    position: relative;
  }
  
  .react-autosuggest__input {
    width: 240px;
    height: 30px;
    padding: 10px 20px;
    font-family: Helvetica, sans-serif;
    font-weight: 300;
    font-size: 16px;
    border: 1px solid #aaa;
    border-radius: 4px;
  }
  
  .react-autosuggest__input--focused {
    outline: none;
  }
  
  .react-autosuggest__input--open {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }
  
  .react-autosuggest__suggestions-container {
    display: none;
  }
  
  .react-autosuggest__suggestions-container--open {
    display: block;
    position: absolute;
    top: 51px;
    width: 280px;
    border: 1px solid #aaa;
    background-color: #fff;
    font-family: Helvetica, sans-serif;
    font-weight: 300;
    font-size: 16px;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
    z-index: 2;
  }

  .autosuggest-subtext {
    font-size: 11px; 
  }
  
  .react-autosuggest__suggestions-list {
    margin: 0;
    padding: 0;
    list-style-type: none;
  }
  
  .react-autosuggest__suggestion {
    cursor: pointer;
    padding: 10px 20px;
  }
  
  .react-autosuggest__suggestion--highlighted {
    background-color: #ddd;
  }
  